// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-concept-js": () => import("./../../../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-crea-js": () => import("./../../../src/pages/crea.js" /* webpackChunkName: "component---src-pages-crea-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-deliverables-[slug]-js": () => import("./../../../src/pages/deliverables/[slug].js" /* webpackChunkName: "component---src-pages-deliverables-[slug]-js" */),
  "component---src-pages-for-silvia-js": () => import("./../../../src/pages/for-silvia.js" /* webpackChunkName: "component---src-pages-for-silvia-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-graphic-material-js": () => import("./../../../src/pages/graphic-material.js" /* webpackChunkName: "component---src-pages-graphic-material-js" */),
  "component---src-pages-hellenic-agricultural-organization-js": () => import("./../../../src/pages/hellenic-agricultural-organization.js" /* webpackChunkName: "component---src-pages-hellenic-agricultural-organization-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-sousse-university-js": () => import("./../../../src/pages/sousse-university.js" /* webpackChunkName: "component---src-pages-sousse-university-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-universidad-catolica-de-murcia-js": () => import("./../../../src/pages/universidad-catolica-de-murcia.js" /* webpackChunkName: "component---src-pages-universidad-catolica-de-murcia-js" */),
  "component---src-pages-universita-politecnica-delle-marche-js": () => import("./../../../src/pages/universita-politecnica-delle-marche.js" /* webpackChunkName: "component---src-pages-universita-politecnica-delle-marche-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

